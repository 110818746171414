import React from "react";
import GeneralInfoPart from "./GeneralInfoPart";
import SubInfoPart from "./SubInfoPart";
import OptionalPart from "./OptionalPart";
import Stepper from "./Stepper";

function useRegisterSteps({ form, handleChange }) {
  const steps = [
    {
      title: "1",
      Component: OptionalPart,
    },
    {
      title: "2",
      Component: SubInfoPart,
    },
    {
      title: "3",
      Component: GeneralInfoPart,
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    if (steps.length - 1 === activeStep) return;
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) return;
    setActiveStep(activeStep - 1);
  };

  const Component = steps[activeStep].Component;

  return {
    activeStep,
    steps,
    isNext: activeStep < steps.length - 1,
    haveNext: activeStep < steps.length - 1,
    havePrev: activeStep > 0,
    handleNext,
    handleBack,
    ActivePart: (
      <Component
        form={form}
        handleChange={handleChange}
        handleNext={handleNext}
        handleBack={handleBack}
      />
    ),
    Stepper: (
      <Stepper
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    ),
  };
}

export default useRegisterSteps;
