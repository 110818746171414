import React from "react";
import st from "./styles.module.scss";

const MainPart = () => {
  return (
    <>
      <div className={st.slide}>
        <a
          href="https://www.california-gym.com/tn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/california.png" alt="" />
        </a>
      </div>
      <div className={st.slide}>
        <a
          href="https://www.decathlon.tn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/decat.png" alt="" />
        </a>
      </div>
      <div className={st.slide}>
        <a
          href="https://greatsportnutrition.com.tn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/gsm.png" alt="" />
        </a>
      </div>
      <div className={st.slide}>
        <a
          href="https://www.impactnutrition.com.tn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/impact.png" alt="" />
        </a>
      </div>
      <div className={st.slide}>
        <a
          href="https://www.lesmills.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/lesmis.png" alt="" />
        </a>
      </div>
      <div className={st.slide}>
        <a
          href="https://www.sem4gym.tn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/sem.png" alt="" />
        </a>
      </div>
    </>
  );
};

function InfinitySlider() {
  return (
    <div className={st.slider}>
      <div className={st["slide-track"]}>
        <MainPart />
        <MainPart />

        {/* the clone  */}

        <MainPart />
        <MainPart />
      </div>
    </div>
  );
}

export default InfinitySlider;
