import React from "react";
import styles from "Pages/Auth/Login.module.scss";

import TextInput from "Components/Input/TextInput/TextInput";
import SelectInput from "Components/Input/TextInput/SelectInput";
import gender from "Assets/Data/Gender.json";
import location from "Assets/Data/Location.json";
import DateInput from "Components/Input/TextInput/DateInput";

export const SubInfoPart = ({ form, handleChange }) => {
  return (
    <>
      <SelectInput
        type="text"
        className={styles["w-100"]}
        placeholder="Please select a gender"
        value={form.gender}
        name="gender"
        items={gender}
        onChange={handleChange}
      />

      <TextInput
        type="number"
        className={styles["w-100"]}
        placeholder="Height (cm)"
        value={form.height}
        name="height"
        onChange={handleChange}
      />

      <TextInput
        type="number"
        className={styles["w-100"]}
        placeholder="Weight (kg)"
        value={form.weight}
        name="weight"
        onChange={handleChange}
      />

      {/* <SelectInput
        type="text"
        className={styles["w-100"]}
        placeholder="Please select a city"
        value={form.city}
        name="city"
        items={location}
        onChange={handleChange}
      /> */}
    </>
  );
};

export default SubInfoPart;
