import Flex from "Components/Flex/Flex";
import React, { useEffect } from "react";
import styles from "../FindTrainerPopup.module.scss";
import Button from "Components/Button/Button";
import { workoutDays } from "Pages/PersonalCoach/filterData";
import toast from "react-hot-toast";
import CheckText from "./CheckText";

function WorkoutDaysOfWeek({
  handle_next = () => {},
  handle_prev = () => {},
  theme = "main",
}) {
  const [selecteds, setselected] = React.useState([]);

  const handleSelect = (value) => {
    if (selecteds.includes(value)) {
      setselected(selecteds.filter((item) => item !== value));
    } else {
      setselected([...selecteds, value]);
    }
  };

  const SubmitAndNext = () => {
    if (selecteds.length === 0) {
      toast.error("Please select at least one option");
      return;
    }

    localStorage.setItem("workout-days", JSON.stringify(selecteds));
    handle_next();
  };

  useEffect(() => {
    let selected = JSON.parse(localStorage.getItem("workout-days") || "[]");
    if (selected.length > 0) {
      setselected(selected);
    }
  }, []);

  return (
    <Flex flex="between" className={`${styles.main} no-scroll-bar`}>
      <div className={styles.header}>
        <h1>Weekly Workout Frequency</h1>
      </div>

      <Flex className={`${styles.body} no-scroll-bar`}>
        <div className="w-full max-h-[300px] md:max-h-[600px] overflow-y-auto no-scroll-bar space-y-2">
          {workoutDays.map((item, index) => {
            return (
              <CheckText
                color={theme}
                key={index}
                text={item.label}
                checked={selecteds.includes(item.value)}
                handle_check={() => {
                  handleSelect(item.value);
                }}
              />
            );
          })}
        </div>
      </Flex>

      <Flex flex="end" className={styles.footer}>
        <Button onClick={handle_prev} color="black">
          Previus
        </Button>
        <Button onClick={SubmitAndNext} color={theme}>
          Next
        </Button>
      </Flex>
    </Flex>
  );
}

export default WorkoutDaysOfWeek;
