import Flex from "Components/Flex/Flex";
import React, { useState } from "react";
import styles from "../FindTrainerPopup.module.scss";
import Button from "Components/Button/Button";
import { useDispatch } from "react-redux";
import useForm from "Hooks/UseForm";
import toast from "react-hot-toast";
import { ActionLogin, ActionRegister } from "Store/user.reducer";

const RegisterPart = ({ handle_next = () => {}, handle_prev = () => {} }) => {
  const [switcher, setSwitcher] = useState(false);

  const handleSwitch = () => {
    setSwitcher(!switcher);
  };

  return (
    <Flex flex="between" className={styles.main}>
      {!switcher && (
        <FormRegister switchFn={handleSwitch} handle_next={handle_next} />
      )}
      {switcher && (
        <FormLogin switchFn={handleSwitch} handle_next={handle_next} />
      )}
    </Flex>
  );
};

const FormLogin = ({ handle_next = () => {}, switchFn = () => {} }) => {
  const dispatch = useDispatch();
  const [form, handleChange] = useForm({
    email: "",
    password: "",
  });

  const handleSubmit = () => {
    dispatch(ActionLogin({ ...form, remember: true }, handle_next));
  };

  return (
    <>
      <div className={styles.header}>
        <h1>Login to Your Account</h1>
        <h3>Access your account to find your perfect trainer quickly!</h3>
      </div>

      <Flex className={styles.body}>
        <input
          type="text"
          placeholder="Your Email"
          onChange={handleChange}
          value={form.email}
          name="email"
        />
        <input
          type="password"
          placeholder="Password"
          onChange={handleChange}
          value={form.password}
          name="password"
        />
        <p className={`text-center w-full mt-2 font-medium`}>
          <span>Don't have an account ? </span>
          <span
            className={"red cursor-pointer font-bold hover:underline"}
            onClick={switchFn}
          >
            Register
          </span>
        </p>
      </Flex>

      <Flex flex="end" className={styles.footer}>
        <Button onClick={handleSubmit}>Next</Button>
      </Flex>
    </>
  );
};

const FormRegister = ({ handle_next = () => {}, switchFn = () => {} }) => {
  const dispatch = useDispatch();

  const [form, handleChange] = useForm({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    height: "",
    weight: "",
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = () => {
    if (form.password !== form.confirmPassword) {
      toast.error("passwords are not the same");
      return;
    }

    if (!form.email) {
      toast.error("email is required");
      return;
    }

    const phoneNumber = form.phone;
    const fullname = form.firstName + " " + form.lastName;
    const email = form.email;
    const password = form.password;
    const height = form.height;
    const weight = form.weight;

    const user = {
      fullname,
      email,
      height,
      weight,
      password,
      phoneNumber,
    };
    dispatch(ActionRegister(user, handle_next));
  };

  return (
    <>
      <div className={styles.header}>
        <h1>Create Your Account</h1>
        <h3>Join us today to find your perfect trainer easily!</h3>
      </div>

      <Flex className={styles.body}>
        <div className="w-full flex items-center justify-between space-x-2">
          <input
            type="text"
            placeholder="First Name"
            onChange={handleChange}
            value={form.firstName}
            name="firstName"
          />
          <input
            type="text"
            placeholder="Last Name"
            onChange={handleChange}
            value={form.lastName}
            name="lastName"
          />
        </div>
        <input
          type="text"
          placeholder="Your Email"
          onChange={handleChange}
          value={form.email}
          name="email"
        />
        <input
          type="text"
          placeholder="Phone Number (eg +216 12 345 678)"
          onChange={handleChange}
          value={form.phone}
          name="phone"
        />
        <div className="w-full flex items-center justify-between space-x-2">
          <input
            type="number"
            placeholder="Height (cm)"
            onChange={handleChange}
            value={form.height}
            name="height"
          />
          <input
            type="number"
            placeholder="Weight (Kg)"
            onChange={handleChange}
            value={form.weight}
            name="weight"
          />
        </div>
        <input
          type="password"
          placeholder="Password"
          onChange={handleChange}
          value={form.password}
          name="password"
        />
        <input
          type="password"
          placeholder="Confirm Password"
          onChange={handleChange}
          value={form.confirmPassword}
          name="confirmPassword"
        />
        <p className={`text-center w-full mt-2 font-medium`}>
          <span>Already have an account ? </span>
          <span
            className={"red cursor-pointer font-bold hover:underline"}
            onClick={switchFn}
          >
            Login
          </span>
        </p>
      </Flex>

      <Flex flex="end" className={styles.footer}>
        <Button onClick={handleSubmit}>Next</Button>
      </Flex>
    </>
  );
};

export default RegisterPart;
