import React, { useEffect, useState } from "react";
import styles from "./OneCoach.module.scss";
import Button from "../../Components/Button/Button";

import mark from "../../Assets/Svgs/icon_coach/mark.svg";

import TitleWithLine from "../../Components/H/TitleWithLine";
import GoBack from "../../Components/GoBack/GoBack";
import { useParams } from "react-router-dom";
import Flex from "../../Components/Flex/Flex.jsx";
import { GapNavbarSpacer } from "../../Layouts/Navbar/Navbar.jsx";
import customAxios from "../../Utils/axios/axios.js";
import ImgBack from "../../Components/Image/ImgBack.jsx";

import educ from "../../Assets/Images/icons/education.png";
import cert from "../../Assets/Images/icons/medaille.png";
import CoachPopup from "../Pupups/CoachPopup/CoachPopup.jsx";
import useMustRegister from "Common/useMustLogin";

function OneCoach() {
  const { id } = useParams();
  const [oneCoash, setOneCoash] = useState(null);
  const [certifs, setCertifs] = useState([]);
  const [open, setOpen] = useState(false);
  const { MustRegister, onClick_withExistUser } = useMustRegister();

  const handleOpenCoachPopup = () => {
    onClick_withExistUser(() => {
      setOpen(true);
    });
  };

  useEffect(() => {
    customAxios
      .get(`/api/coache/get-one/${id}`)
      .then((resp) => {
        setOneCoash(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    customAxios.get(`/api/certifs/get-all/${id}`).then((res) => {
      setCertifs(res.data.data);
    });
  }, [id]);

  if (!oneCoash) {
    return <></>;
  }

  return (
    <div className={styles.main}>
      <MustRegister />
      <CoachPopup
        open={open}
        oneCoash={oneCoash}
        handle_close={() => {
          setOpen(!open);
        }}
      />
      <GapNavbarSpacer />
      <div className={styles.header}>
        <ImgBack
          src={oneCoash.CoverProfileImage}
          className={styles.cover}
          alt=""
        />
        <div className={styles.filter} />
        <GoBack color="white" to="/personal-coach" className={styles.content}>
          Back to Personal Trainers
        </GoBack>
      </div>

      <div className={styles.header2}>
        <div className={styles.avatar}>
          <div className={styles.avatar_img}>
            <ImgBack src={oneCoash.MainImg} alt="" />
          </div>

          <div className={styles.name}>
            <h3>{oneCoash.fullname}</h3>
            <span>
              Welcome to <strong>TRENA</strong>
            </span>
          </div>
        </div>
        <Button
          size="normal"
          className={styles.btn}
          onClick={handleOpenCoachPopup}
        >
          {/* <NavLink to="/login"> */}
          Contact {oneCoash.fullname}
          {/* </NavLink> */}
        </Button>
      </div>

      <div className={styles.body}>
        <AboutAndCertifs Services={oneCoash.Services} />
        {certifs.length > 0 && <EducsAndCerts certifs={certifs} />}
        <Gallery galleries={oneCoash.Images} />
        {/* <DiscoverMore video={coach.VideoLink} /> */}
      </div>
    </div>
  );
}

const AboutAndCertifs = ({ Services = [] }) => {
  return (
    <div className={styles.about_and_certifs}>
      <About />
      <Certifs Services={Services} />
    </div>
  );
};

const EducsAndCerts = ({ certifs = [] }) => {
  return (
    <div className={styles.education_and_certifs}>
      <TitleWithLine className={styles.textWithLine} is_white>
        Educations & Certifications
      </TitleWithLine>
      <Flex
        flex={certifs.length === 2 ? "start" : "between"}
        className={styles.items}
      >
        {certifs.map((item, key) => {
          return (
            <div key={key} className={styles.item}>
              <Flex flex="start">
                <img src={item.type === "Certification" ? cert : educ} alt="" />
                <span>{item.type}</span>
              </Flex>
              <p>
                {item.title} {/*item.desceiption*/}
              </p>
            </div>
          );
        })}
      </Flex>
    </div>
  );
};

const Gallery = ({ galleries = [] }) => {
  return (
    <div className={styles.gallery}>
      <TitleWithLine className={styles.textWithLine}> Gallery </TitleWithLine>
      <div className={styles.images}>
        {galleries.map((img, index) => {
          return (
            <div className={styles.img} key={index}>
              <ImgBack src={img} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

// const DiscoverMore = ({ video = "" }) => {
//   return (
//     <div className={styles.video_section}>
//       <TitleWithLine> Discover More </TitleWithLine>

//       <div className={styles.video}>
//         <ReactPlayer
//           className={styles.react_player}
//           url={video}
//           controls
//           width="100%"
//           height="100%"
//           autoplay={false}
//         />
//       </div>
//     </div>
//   );
// };

const About = () => {
  return (
    <div className={styles.about}>
      <h1>About</h1>
      <p>
        Welcome to <strong> TRENA</strong>, where we're more than just a fitness
        platform; we're your dedicated partner in crafting a healthier life. Our
        mission encompasses three vital pillars:
      </p>

      <p>
        Welcome to <strong> TRENA</strong>, where we're more than just a fitness
        platform; we're your dedicated partner in crafting a healthier life. Our
        mission encompasses three vital pillars:
      </p>

      {/* <p>
        Welcome to <strong> TRENA</strong>, where we're more than just a fitness
        platform; we're your dedicated partner in crafting a healthier life. Our
        mission encompasses three vital pillars:
      </p> */}
    </div>
  );
};

const Certifs = ({ Services = [] }) => {
  return (
    <div className={styles.certif_container}>
      <div className={styles.certifs}>
        {/* {Services.map((item, key) => {
          return <Certif key={key} Label={item.title} />;
        })} */}

        <Certif Label="Service" />
        <Certif Label="Service" />
        <Certif Label="Service" />
        <Certif Label="Service" />
      </div>
    </div>
  );
};

const Certif = ({ Label }) => {
  return (
    <div className={styles.certif}>
      <h1>
        <img src={mark} alt="" /> {Label}
      </h1>
      <p>
        We tailor workouts to your unique goals and adapt as you progress,
        ensuring a customized fitness journey.
      </p>
    </div>
  );
};

export default OneCoach;
