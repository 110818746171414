import React from "react";
import styles from "./HeadPage.module.scss";

import temp_img from "../../../Assets/Images/home-head1.png";
import Button from "../../../Components/Button/Button";
import Flex from "../../../Components/Flex/Flex";
import FindTrainerPopup from "../../Pupups/FindAPersonCN/FindTrainerPopup";
import usePopup from "../../../Hooks/usePupup";
import FindNutriPopup from "Pages/Pupups/FindAPersonCN/FindNutriPopup";

function HeadPage() {
  const [open, handle_open, handle_close] = usePopup();
  const [open2, handle_open2, handle_close2] = usePopup();

  return (
    <div className={styles.main}>
      <FindTrainerPopup handle_close={handle_close} open={open} />
      <FindNutriPopup handle_close={handle_close2} open={open2} />

      <img src={temp_img} alt="temp_img" className={styles.bg_img} />

      <div className={styles.content}>
        {/* <h1>Unleash Your TRENA’thlete</h1> */}
        <h1>The #1 Platform for Fitness in Tunisia</h1>
        {/* <h2>
          Tired of searching for the perfect fitness trainer or nutritionist ?{" "}
          <br /> Find top Tunisian experts matched to your exact goals.
        </h2> */}
        <h2>
          Fitness in one place. workouts, nutrition, expert tips, and a
          supportive community. <br /> Reach your goals faster and easier with
          TRENA!
        </h2>
        <Flex className={styles.btns}>
          <Button
            size="verry-big"
            onClick={() => {
              setTimeout(() => {
                handle_open();
              }, 200);
            }}
          >
            Find a Trainer
          </Button>
          <Button
            size="verry-big"
            color="green"
            onClick={() => {
              setTimeout(() => {
                handle_open2();
              }, 200);
            }}
          >
            Find a Nutritionist
          </Button>
        </Flex>
      </div>
    </div>
  );
}

export default HeadPage;
