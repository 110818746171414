import React from "react";
import styles from "../FindTrainerPopup.module.scss";
import Flex from "Components/Flex/Flex";
import circle_red from "../../../../Assets/Svgs/general_icons/circle_red.svg";

const RadioText = ({
  text = "",
  checked = false,
  handle_check,
  color = "main",
}) => {
  let checked_style = checked ? styles[`checked-color-${color}`] : "";

  return (
    <Flex
      flex="start"
      className={`${styles.check} ${checked_style}`}
      onClick={handle_check}
    >
      <Flex className={`${styles.checkbox} ${styles.radiobox}`}>
        {checked && <div className={styles.centerItem} />}
      </Flex>
      <h2 className={styles.text}>{text}</h2>
    </Flex>
  );
};

export default RadioText;
