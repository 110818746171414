import React, { useEffect, useState } from "react";
import styles from "./PersonalCoach.module.scss";
import H1 from "../../Components/H/H1";
import CardCoach from "../../Components/Cards/CardCoach/CardCoach";
import { GapNavbarSpacer } from "../../Layouts/Navbar/Navbar";
import customAxios from "../../Utils/axios/axios";
import FilterBtn from "../../Components/Form/FilterBtn/FilterBtn";
import { Gender, ServicesMidle, Location } from "./filterData";
import { useFromFindCoachPopup } from "./useFromFindPopup";

function PersonalCoach() {
  const [coaches, setCoashes] = useState([]);
  const [services, setServices] = useState([]);

  const [filter, setFilter] = useState({
    services: [],
    locations: [],
    gender: [],
  });

  useFromFindCoachPopup({ setFilter });

  useEffect(() => {
    customAxios
      .get("/api/coache/get-all", {
        params: {
          filter: JSON.stringify(filter),
        },
      })
      .then((resp) => {
        setCoashes(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [filter]);

  useEffect(() => {
    customAxios.get("/api/sport/get-all").then((resp) => {
      setServices(resp.data.data);
    });
  }, []);

  return (
    <div className={styles.main}>
      <GapNavbarSpacer />
      <H1 className={styles.h1}>Want Better Results?</H1>
      <p className={styles.main_descrp}>
        Welcome to <strong> TRENA </strong>, where we're more than just a
        fitness platform; we're your dedicated <br /> partner in crafting a
        healthier life. Our mission encompasses three vital pillars:
      </p>

      <div className={styles.filters}>
        <FilterBtn
          itemName="Services"
          items={ServicesMidle(services)}
          setFilter={(f) => {
            setFilter({ ...filter, services: f });
          }}
          filter={filter.services}
        />

        <FilterBtn
          itemName="Gender"
          filter={filter.gender}
          setFilter={(f) => {
            setFilter({ ...filter, gender: f });
          }}
          items={Gender}
        />

        <FilterBtn
          itemName="Location"
          filter={filter.locations}
          setFilter={(f) => {
            setFilter({ ...filter, locations: f });
          }}
          items={Location}
        />
      </div>

      <div className={styles.trainers}>
        {coaches.map((item, index) => {
          return (
            <CardCoach
              className={styles.trainer}
              white_card
              data={item}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}

export default PersonalCoach;
