import React from "react";
import styles from "Pages/Auth/Login.module.scss";

import Flex from "Components/Flex/Flex";
import Button from "Components/Button/Button";
import useForm from "Hooks/UseForm";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ActionRegister } from "Store/user.reducer";
import TextInput from "Components/Input/TextInput/TextInput";
import PasswordInput from "Components/Input/PasswordInput/PasswordInput";
import { Header, WithGLAndFB } from "Pages/Auth/Auth";
import DateInput from "Components/Input/TextInput/DateInput";

export const GeneralInfoPart = ({ form, handleChange }) => {
  return (
    <>
      <TextInput
        type="text"
        className={styles["w-50"]}
        placeholder="First Name"
        value={form.firstName}
        name="firstName"
        onChange={handleChange}
      />
      <TextInput
        type="text"
        className={styles["w-50"]}
        placeholder="Last Name"
        value={form.lastName}
        name="lastName"
        onChange={handleChange}
      />

      <TextInput
        type="text"
        className={styles["w-100"]}
        placeholder="Email"
        value={form.email}
        name="email"
        onChange={handleChange}
      />

      <DateInput
        type="text"
        className={styles["w-100"]}
        placeholder="Date of Birth"
        value={form.birthDate}
        name="birthDate"
        onChange={handleChange}
      />

      {/* <TextInput
        type="text"
        className={styles["w-25"]}
        placeholder="+216"
        disabled={true}
        value={form.phoneCode}
        name="phoneCode"
        onChange={handleChange}
      /> */}

      {/* <TextInput
        type="text"
        className={styles["w-75"]}
        placeholder="Phone Number ..."
        value={form.phone}
        name="phone"
        onChange={handleChange}
      /> */}

      <PasswordInput
        className={styles["w-100"]}
        placeholder="Password"
        value={form.password}
        name="password"
        onChange={handleChange}
      />

      <PasswordInput
        className={styles["w-100"]}
        placeholder="Confirm Password"
        value={form.confirmPassword}
        name="confirmPassword"
        onChange={handleChange}
      />
    </>
  );
};

export default GeneralInfoPart;
