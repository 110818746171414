import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import styles from "./styles.module.scss";
import { Cookie } from "lucide-react";

const real_langs = {
  GB: "en",
  FR: "fr",
  SA: "ar",
};

function Select({
  isUp = false,
  shorted = false,
  noshebron = false,
  handleLanguageChange = () => {},
}) {
  const [selected, setSelected] = useState("GB");

  useEffect(() => {
    let lang = localStorage.getItem("lang");
    if (lang) {
      setSelected(lang);
    } else {
      setSelected("GB");
      handleLanguageChange("en");
    }
  }, []);

  const HandlelangChange = (e) => {
    localStorage.setItem("lang", e);
    handleLanguageChange(real_langs[e]);
    setSelected(e);
    // window.location.reload();
  };

  return (
    <div className={`${styles.container}`}>
      <ReactFlagsSelect
        fullWidth={!shorted}
        selectButtonClassName={
          shorted || noshebron ? styles.selectButtonNoChevron : ""
        }
        showSecondarySelectedLabel={!shorted}
        showSelectedLabel={!shorted}
        customLabels={{ GB: "ENGLISH", FR: "FRENCH", SA: "ARABIC" }}
        countries={["GB", "FR", "SA"]}
        className={`${styles.langSelect} ${isUp ? styles.isUp : ""}`}
        selected={selected}
        onSelect={(code) => HandlelangChange(code)}
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
        placeholder="Select Language"
      />
    </div>
  );
}

export default Select;
