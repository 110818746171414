import Flex from "Components/Flex/Flex";
import React, { useEffect } from "react";
import styles from "../FindTrainerPopup.module.scss";
import RadioText from "./RadioText";
import Button from "Components/Button/Button";
import { CoachingStyle } from "Pages/PersonalCoach/filterData";
import toast from "react-hot-toast";

function CoachingStylePart({
  handle_next = () => {},
  handle_prev = () => {},
  theme = "main",
}) {
  const [selected, setselected] = React.useState(null);

  const SubmitAndNext = () => {
    if (!selected) {
      toast.error("Please select an option");
      return;
    }

    localStorage.setItem("coaching-style", selected);

    handle_next();
  };

  useEffect(() => {
    let location = localStorage.getItem("coaching-style");
    if (location) {
      setselected(location);
    }
  }, []);

  return (
    <Flex flex="between" className={`${styles.main} no-scroll-bar`}>
      <div className={styles.header}>
        <h1>Coaching Style</h1>
      </div>

      <Flex className={`${styles.body} no-scroll-bar`}>
        <div className="w-full max-h-[300px] md:max-h-[600px] overflow-y-auto no-scroll-bar space-y-2">
          {CoachingStyle.map((item, index) => {
            return (
              <RadioText
                color={theme}
                key={index}
                text={item.label}
                checked={selected === item.value}
                handle_check={() => setselected(item.value)}
              />
            );
          })}
        </div>
      </Flex>

      <Flex flex="end" className={styles.footer}>
        <Button onClick={handle_prev} color="black">
          Previus
        </Button>
        <Button onClick={SubmitAndNext} color={theme}>
          Next
        </Button>
      </Flex>
    </Flex>
  );
}

export default CoachingStylePart;
