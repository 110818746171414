import H1 from "Components/H/H1";
import React from "react";

const Header = ({ title = "", parag = "" }) => {
  return (
    <div>
      <H1 className="text-center mt-[2vw]">{title}</H1>
      <p className="w-full xl:w-1/3 text-center mx-auto mt-[1vw] xl:mt-0 mb-[3vw] text-[3vw] sm:text-[2vw] md:text-[1.5vw] xl:text-[1vw]">
        {parag}
      </p>
    </div>
  );
};
export default Header;
