import React from "react";
import styles from "./Button.module.scss";

function Button({
  size = "normal", // verry-big | big | normal | small
  color = "main", // black | white | main | green
  children = "",
  className = "",
  onClick = () => {},
}) {
  return (
    <button
      className={`
      ${styles.main} 
      ${styles[size]} 
      ${className} 
      ${styles[color + "-color"]}
      `}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export const Centerer = ({ children, className = "" }) => {
  return <div className={`${styles.centerer} ${className}`}>{children}</div>;
};

export const Lefter = ({ children, className = "" }) => {
  return <div className={`${styles.lefter} ${className}`}>{children}</div>;
};

export const Righter = ({ children, className = "" }) => {
  return <div className={`${styles.righter} ${className}`}>{children}</div>;
};

export default Button;
