export const Gender = [
  {
    label: "Male",
    value: "MEN",
  },
  {
    label: "Female",
    value: "WOMEN",
  },
];

export const Gender2 = [
  {
    label: "Male",
    value: "MEN",
  },
  {
    label: "Female",
    value: "WOMEN",
  },
  {
    label: "No Preference",
    value: "No Preference",
  },
];

export const Location = [
  {
    label: "Ariana",
    value: "Ariana",
  },
  {
    label: "Beja",
    value: "Beja",
  },
  {
    label: "Bizerte",
    value: "Bizerte",
  },
  {
    label: "El Kef",
    value: "El Kef",
  },
  {
    label: "Gabès",
    value: "Gabès",
  },
  {
    label: "Gafsa",
    value: "Gafsa",
  },
  {
    label: "Hammamet",
    value: "Hammamet",
  },
  {
    label: "Jendouba",
    value: "Jendouba",
  },
  {
    label: "Kairouan",
    value: "Kairouan",
  },
  {
    label: "Kasserine",
    value: "Kasserine",
  },
  {
    label: "La Marsa",
    value: "La Marsa",
  },
  {
    label: "Mahdia",
    value: "Mahdia",
  },
  {
    label: "Medenine",
    value: "Medenine",
  },
  {
    label: "Monastir",
    value: "Monastir",
  },
  {
    label: "Nabeul",
    value: "Nabeul",
  },
  {
    label: "Sfax",
    value: "Sfax",
  },
  {
    label: "Sousse",
    value: "Sousse",
  },
  {
    label: "Tataouine",
    value: "Tataouine",
  },
  {
    label: "Tozeur",
    value: "Tozeur",
  },
  {
    label: "Tunis",
    value: "Tunis",
  },
];

export const workoutDays = [
  {
    label: "Everyday",
    value: "Everyday",
  },
  {
    label: "Monday",
    value: "Monday",
  },
  {
    label: "Tuesday",
    value: "Tuesday",
  },
  {
    label: "Wednesday",
    value: "Wednesday",
  },
  {
    label: "Thursday",
    value: "Thursday",
  },
  {
    label: "Friday",
    value: "Friday",
  },
  {
    label: "Saturday",
    value: "Saturday",
  },
  {
    label: "Sunday",
    value: "Sunday",
  },
  {
    label: "No specific day",
    value: "No specific day",
  },
];

export const CoachingStyle = [
  { label: "In-Person", value: "In-Person" },
  { label: "Online", value: "Online" },
  { label: "Hybrid (Combination of In-Person and Online)", value: "Hybrid" },
];

export const ServicesMidle = (services) => {
  return services.map((item) => {
    return {
      label: item?.title,
      value: item?._id,
    };
  });
};
