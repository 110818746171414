import React from "react";

const Switcher = (props) => {
  const { showType, setShowType } = props;

  return (
    <div className="px-[1vw] lg:px-[0.5vw] py-[1vw] lg:py-[0.5vw] mb-[8vw] lg:mb-[5vw] lg:text-[1.1vw] gap-[1vw] lg:gap-[0.5vw] rounded-[1vw] lg:rounded-[0.6vw] w-fit font-semibold bg-bgGrayDark mx-auto flex items-stretch justify-center">
      <SwitcherButton
        active={showType === "monthly"}
        onClick={() => setShowType("monthly")}
      >
        Monthly
      </SwitcherButton>
      <SwitcherButton
        active={showType === "annually"}
        onClick={() => setShowType("annually")}
      >
        Annually
        <span className="text-mainColor block text-[2.5vw] sm:text-[1.5vw] lg:text-[0.7vw]">
          Get 2 Months FREE!
        </span>
      </SwitcherButton>
    </div>
  );
};

const SwitcherButton = ({ children, active = false, onClick }) => {
  let activeClass = active ? "text-white bg-black" : "text-black";

  return (
    <div
      onClick={onClick}
      className={`px-[3vw] lg:px-[2vw] py-[1.3vw] lg:py-[0.5vw] rounded-[1vw] leading-0 lg:rounded-[0.3vw] text-center cursor-pointer transition-all duration-200 flex items-center justify-center flex-col ${activeClass}`}
    >
      {children}
    </div>
  );
};

export default Switcher;
