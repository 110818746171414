import React from "react";
import styles from "Pages/Auth/Login.module.scss";
import TextInput from "Components/Input/TextInput/TextInput";
import SelectInput from "Components/Input/TextInput/SelectInput";
import Level from "Assets/Data/Level.json";

export const OptionalPart = ({ form, handleChange }) => {
  return (
    <>
      <SelectInput
        type="text"
        className={styles["w-100"]}
        placeholder="Select Your Fitness Goals"
        value={form.goal}
        name="goal"
        items={[
          {
            value: "Weight Loss",
            label: "Weight Loss",
          },
          {
            value: "Muscle Building",
            label: "Muscle Building",
          },
          {
            value: "Endurance Training",
            label: "Endurance Training",
          },
          {
            value: "Flexibility Improvement",
            label: "Flexibility Improvement",
          },
          {
            value: "Sports Performance",
            label: "Sports Performance",
          },
        ]}
        onChange={handleChange}
      />

      <SelectInput
        type="text"
        className={styles["w-100"]}
        placeholder="Select experience level"
        value={form.experience_level}
        items={Level}
        name="experience_level"
        onChange={handleChange}
      />

      <SelectInput
        type="text"
        className={styles["w-100"]}
        placeholder="Select Your Workout Frequency"
        value={form.work_frequence}
        name="work_frequence"
        items={[
          {
            value: "1 Day of the week",
            label: "1 Day of the week",
          },
          {
            value: "2 Days of the week",
            label: "2 Days of the week",
          },
          {
            value: "3 Days of the week",
            label: "3 Days of the week",
          },
          {
            value: "4 Days of the week",
            label: "4 Days of the week",
          },
          {
            value: "5 Days of the week",
            label: "5 Days of the week",
          },
          {
            value: "6 Days of the week",
            label: "6 Days of the week",
          },
          {
            value: "7 Days of the week",
            label: "7 Days of the week",
          },
        ]}
        onChange={handleChange}
      />
    </>
  );
};

export default OptionalPart;
