import usePopup from "Hooks/usePupup";
import LoginPopup from "Pages/Pupups/LoginPopup/LoginPopup";
import RegisterPopup from "Pages/Pupups/RegisterPopup/RegisterPopup";
import React from "react";
import { useUser } from "Store/hooks/useUser";

function useMustRegister() {
  const [openR, handle_openR, handle_closeR] = usePopup();
  const [openL, handle_openL, handle_closeL] = usePopup();
  const { is_connected } = useUser();

  const onClick_withExistUser = (onClick = () => {}) => {
    if (!is_connected) {
      handle_openR();
    } else {
      onClick();
    }
  };

  const MustRegister = () => {
    return (
      <>
        <RegisterPopup
          open={openR}
          handle_close={handle_closeR}
          openOther={() => {
            handle_closeR();
            handle_openL();
          }}
        />
        <LoginPopup
          open={openL}
          handle_close={handle_closeL}
          openOther={() => {
            handle_closeL();
            handle_openR();
          }}
        />
      </>
    );
  };

  return { MustRegister, onClick_withExistUser };
}

export default useMustRegister;
