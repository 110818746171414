import React from "react";
import styles from "Pages/Auth/Login.module.scss";

import Flex from "Components/Flex/Flex";
import Button from "Components/Button/Button";
import useForm from "Hooks/UseForm";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ActionRegister } from "Store/user.reducer";
import { Header } from "Pages/Auth/Auth";
import useRegisterSteps from "./useRegisterSteps";
import { VerifExistEmail } from "Services/Auth/verifExistEmail";

export const RegisterComponent = ({ openOther = () => {} }) => {
  const dispatch = useDispatch();

  const [form, handleChange] = useForm({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",

    phoneCode: "+216",
    phone: "",
    city: "",
    birthDate: "",
    gender: "",

    height: "",
    weight: "",
    goal: "",
    experience_level: "",
    work_frequence: "",
  });

  const { ActivePart, Stepper, haveNext, activeStep, handleNext } =
    useRegisterSteps({
      form,
      handleChange,
    });

  const handleSubmit = () => {
    if (activeStep === 2) {
      if (!form.firstName) {
        toast.error("first name is required");
        return;
      }

      if (!form.lastName) {
        toast.error("last name is required");
        return;
      }

      // if (!form.phone) {
      //   toast.error("phone is required");
      //   return;
      // }

      if (!form.email) {
        toast.error("email is required");
        return;
      }

      if (!form.birthDate) {
        toast.error("birth date is required");
        return;
      }

      if (!form.password) {
        toast.error("password is required");
        return;
      }

      if (form.password !== form.confirmPassword) {
        toast.error("passwords are not the same");
        return;
      }

      VerifExistEmail({
        email: form.email,
        succ: () => {
          const fullname = form.firstName + " " + form.lastName;
          const email = form.email;
          const password = form.password;
          const birthDate = form.birthDate;
          // const phoneNumber = form.phoneCode + form.phone;
          // const Location = form.city;
          const height = form.height;
          const weight = form.weight;
          const goal = form.goal;
          const experience_level = form.experience_level;
          const work_frequence = form.work_frequence;
          const sex = form.gender;

          const user = {
            fullname,
            email,
            password,
            height,
            weight,
            // phoneNumber,
            goal,
            experience_level,
            work_frequence,
            birthDate,
            // Location,
            sex,
          };
          dispatch(ActionRegister(user, () => {}));
        },
        fail: () => {
          toast.error("email already exist");
        },
      });
    }

    if (activeStep === 1) {
      if (!form.height) {
        toast.error("height is required");
        return;
      }

      if (!form.weight) {
        toast.error("weight is required");
        return;
      }

      if (!form.gender) {
        toast.error("gender is required");
        return;
      }

      // if (!form.city) {
      //   toast.error("city is required");
      //   return;
      // }

      handleNext();
    }

    if (activeStep === 0) {
      if (!form.goal) {
        toast.error("goal is required");
        return;
      }

      if (!form.experience_level) {
        toast.error("experience level is required");
        return;
      }

      if (!form.work_frequence) {
        toast.error("work frequence is required");
        return;
      }

      handleNext();
    }
  };

  return (
    <div className={styles.container}>
      <Header />
      {Stepper}
      <div className={styles.form}>
        <div className={styles.row}>
          {/* <GeneralInfoPart form={form} handleChange={handleChange} /> */}
          {/* <SubInfoPart form={form} handleChange={handleChange} /> */}
          {ActivePart}
        </div>
      </div>

      <div className={styles.btn_cont}>
        <Button onClick={handleSubmit}>{haveNext ? "Next" : "Register"}</Button>
      </div>

      <div className={styles.otherOptin}>
        <span>Already have an account ? </span>
        <span className={styles.a} onClick={openOther}>
          Login
        </span>
      </div>
    </div>
  );
};

export default RegisterComponent;
