import React from "react";
import HeadPage from "./HeadPage/HeadPage";
import Trainers from "./Trainers/Trainers";
import Blogs from "./Blogs/Blogs";
import Nutretions from "./Nutretions/Nutretions";
import InfinitySlider from "Components/InfinitySlider/InfinitySlider";
import Partners from "Pages/PricingPage/Partners/Partners";
// import About from "./About/About";

function Home() {
  return (
    <div>
      <HeadPage />
      <Trainers />
      <Nutretions />
      <Blogs />
      <Partners />
      {/* <About /> */}
    </div>
  );
}

export default Home;
