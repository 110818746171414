import React, { useEffect, useState } from "react";
import styles from "./Nutretions.module.scss";
import lines_white from "../../../Assets/Svgs/general_icons/3_lines_white.svg";

import Button, { Centerer } from "../../../Components/Button/Button";
import CardCoach from "../../../Components/Cards/CardCoach/CardCoach";
import { NavLink } from "react-router-dom";
import axios from "../../../Utils/axios/axios";
import CardNut from "../../../Components/Cards/CardCoach/CardNut";

function Nutretions() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    axios
      .get("/api/nutritionist/get-all")
      .then((resp) => {
        setdata(resp.data.data.reverse().filter((_, i) => i < 4));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className={styles.main}>
      <h1>
        Meet Our Nutritionists
        {/* <img src={lines_white} alt="" />{" "} */}
      </h1>
      <h4>Eat Right, Feel Great</h4>

      <div className={styles.nutretions}>
        {data.map((item, index) => {
          return <CardNut data={item} key={index} className={styles.nut} />;
        })}
      </div>

      <Centerer className={styles.btn}>
        <Button size="big" color="green">
          <NavLink to="/personal-nutrit">More Nutritionists</NavLink>
        </Button>
      </Centerer>
    </div>
  );
}

export default Nutretions;
