import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import styles from "./styles.module.scss";

function DateInput({
  placeholder = "yyyy-mm-dd",
  initialValue = "",
  name = "",
  onChange = () => {},
  className = "",
  disabled = false,
}) {
  const [selectedDate, setSelectedDate] = useState(
    initialValue ? new Date(initialValue) : null
  );
  const [displayedDate, setDisplayedDate] = useState(
    initialValue ? format(new Date(initialValue), "yyyy-MM-dd") : ""
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      const formattedDate = format(date, "yyyy-MM-dd");
      setDisplayedDate(formattedDate);
      onChange({
        target: {
          name,
          value: formattedDate,
        },
      }); // Pass the formatted date to the parent component
    } else {
      setDisplayedDate(""); // Clear the displayed date if no date is selected
    }
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      customInput={
        <input
          type="text"
          className={`${styles.input} ${className}`}
          placeholder={placeholder}
          value={displayedDate}
          name={name}
          disabled={disabled}
          readOnly
        />
      }
      dateFormat="yyyy-MM-dd"
      placeholderText={placeholder}
      wrapperClassName="w-full"
      popperPlacement="bottom"
    />
  );
}

export default DateInput;
