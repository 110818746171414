import customAxios from "Utils/axios/axios";

export const VerifExistEmail = async ({
  email,
  succ = () => {},
  fail = () => {},
}) => {
  try {
    const res = await customAxios.post("/api/user/verify-email", { email });
    succ();
    return res.data;
  } catch (error) {
    console.error(error);
    fail();
    return { error: true };
  }
};
