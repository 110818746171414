import React from "react";
import styles from "./styles.module.scss";

function SelectInput({
  type = "text",
  placeholder = "",
  value = "",
  name = "",
  onChange = () => {},
  items = [],
  className = "",
  disabled = false,
}) {
  return (
    <select
      type={type}
      className={`${styles.input} ${className}`}
      placeholder={placeholder}
      value={value}
      name={name}
      disabled={disabled}
      onChange={onChange}
    >
      <option value={""} selected className="text-gray-500">
        {placeholder}
      </option>
      {items.map((item, index) => (
        <option key={index} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
}

export default SelectInput;
