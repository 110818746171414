export const datas = {
  free: {
    title: "Free",
    icon: "/svgs/icons/bolt.png",
    price: "0TND/mo",
    description: "billed yearly",
  },
  pro: {
    title: "Pro",
    icon: "/svgs/icons/muslce.png",
    price: "35TND/mo",
    description: "billed yearly",
  },
  premium: {
    title: "Premium",
    icon: "/svgs/icons/shine.png",
    price: "Custom",
    description: "tailored pricing",
  },
};

export const items = [
  // FREE
  { text: "Basic Features", free: 1, pro: 1, premium: 1 },
  { text: "Explore Trenapedia", free: 1, pro: 1, premium: 1 },
  { text: "Limited Programs", free: 1, pro: 1, premium: 1 },
  { text: "Community Forum", free: 1, pro: 1, premium: 1 },
  { text: "Introductory Videos", free: 1, pro: 1, premium: 1 },
  // PRO
  { text: "Access to Coaches", free: 0, pro: 1, premium: 1 },
  { text: "Priority Booking", free: 0, pro: 1, premium: 1 },
  { text: "Exclusive Content", free: 0, pro: 1, premium: 1 },
  { text: "Goal-Oriented Programs", free: 0, pro: 1, premium: 1 },
  { text: "Progress Reports", free: 0, pro: 1, premium: 1 },
  { text: "Discounts on Premium Content", free: 0, pro: 1, premium: 1 },
  // PREMIUM
  { text: "Personalized Nutrition Plans", free: 0, pro: 0, premium: 1 },
  { text: "Direct Messaging", free: 0, pro: 0, premium: 1 },
  { text: "Unlimited Coaching Sessions", free: 0, pro: 0, premium: 1 },
  { text: "Exclusive Group Classes & Events", free: 0, pro: 0, premium: 1 },
  { text: "Advanced Fitness Challenges", free: 0, pro: 0, premium: 1 },
  { text: "Merchandise Discounts", free: 0, pro: 0, premium: 1 },
  { text: "Wellness Dashboard", free: 0, pro: 0, premium: 1 },
];
