import React from "react";
import styles from "./styles.module.scss";

function TextInput({
  type = "text",
  placeholder = "",
  value = "",
  name = "",
  onChange = () => {},
  className = "",
  disabled = false,
}) {
  return (
    <input
      type={type}
      className={`${styles.input} ${className}`}
      placeholder={placeholder}
      value={value}
      name={name}
      disabled={disabled}
      onChange={onChange}
    />
  );
}

export default TextInput;
