import React, { useEffect, useState } from "react";
import st from "./Navbar.mobile.module.scss";
import logo from "../../Assets/Svgs/logo_white.svg";
import { NavLink, useLocation } from "react-router-dom";
import usePopup from "../../Hooks/usePupup";
import { useSelector } from "react-redux";
import Button from "../../Components/Button/Button";
import { useMediaPredicate } from "react-media-hook";

import { IoMdClose } from "react-icons/io";
import { FiMenu } from "react-icons/fi";
import useIsCurrentUrl from "../../Hooks/UseIsCurrentUrl";
import useCondRet from "../../Hooks/UseCondRet";
import { useOnScroll } from "../../Hooks/useOnScroll";
import RegisterPopup from "../../Pages/Pupups/RegisterPopup/RegisterPopup";
import LoginPopup from "../../Pages/Pupups/LoginPopup/LoginPopup";
import { ChevronDown, ChevronUp } from "lucide-react";
import GoogleTranslate from "language/GoogleTranslate";

function NavbarMobile() {
  const path = useLocation();
  const [openR, handle_openR, handle_closeR] = usePopup();
  const [openL, handle_openL, handle_closeL] = usePopup();
  const lessThan = useMediaPredicate("(max-width: 1280px)");

  const { is_connected } = useSelector((state) => state.UserReducer);
  let is_home = useCondRet(useIsCurrentUrl("/"), "", st.not_in_home);
  const is_scrolled = useOnScroll(80) || lessThan ? st.is_scrolled : "";

  const [openNav, setOpenNav] = useState(true);
  const [collapseProf, setCollapseProf] = useState(false);
  const handleNav = () => {
    setOpenNav(!openNav);
  };

  useEffect(() => {
    if (is_connected) {
      handle_closeL();
      handle_closeR();
    }
  }, [is_connected]);

  useEffect(() => {
    setOpenNav(false);
  }, [path]);

  return (
    <div className={`${st.main} ${is_home} ${is_scrolled}`}>
      <RegisterPopup
        open={openR}
        handle_close={handle_closeR}
        openOther={() => {
          handle_closeL();
          handle_openR();
        }}
      />
      <LoginPopup
        open={openL}
        handle_close={handle_closeL}
        openOther={() => {
          handle_closeR();
          handle_openL();
        }}
      />

      <div className={st.navnormal}>
        <NavLink to="/">
          <img src={logo} alt="trena" />
        </NavLink>
        {!openNav && <FiMenu className={st.burger} onClick={handleNav} />}
        {openNav && <IoMdClose className={st.burger} onClick={handleNav} />}
      </div>
      <div className={`${st.navbar} ${openNav ? st.open : ""}`}>
        <div className={st.top}>
          <NavLink to="/">
            <img src={logo} alt="trena" />
          </NavLink>
          <IoMdClose className={st.close} onClick={handleNav} />
        </div>
        <div className={st.mid}>
          <NavLink to="/" className={st.link__item}>
            Home
          </NavLink>
          {/* <NavLink to="/about-us" className={st.link__item}>
            About Us
          </NavLink> */}

          <div
            className={`${st.link__item} ${st.collapser}`}
            onClick={() => {
              setCollapseProf(!collapseProf);
            }}
          >
            <p>Wellness Experts</p>
            {collapseProf ? <ChevronUp /> : <ChevronDown />}
          </div>
          <div
            className={`${st.collapser_part} ${
              collapseProf ? st.collapser_part_open : ""
            }`}
          >
            <NavLink to="/personal-coach" className={`${st.link__item}`}>
              Personal Trainers
            </NavLink>
            <NavLink to="/personal-nutrit" className={`${st.link__item}`}>
              Nutritionists
            </NavLink>
          </div>

          <NavLink to="/pricing" className={st.link__item}>
            Pricing
          </NavLink>
          <NavLink to="/trenapedia" className={st.link__item}>
            Trenapedia
          </NavLink>
          <div className={`${st.link__item} ${st.store}`}>
            <NavLink to="https://trena.store/" target="_blank" rel="noreferrer">
              Store
            </NavLink>
          </div>
          <NavLink to="/contact-us" className={st.link__item}>
            Contact us
          </NavLink>
        </div>
        <div className={st.bot}>
          <GoogleTranslate isUp noshebron />
          <div className={st.auth_part}>
            {!is_connected && (
              <div className={st.btn_register} onClick={handle_openL}>
                LOGIN
              </div>
            )}

            {!is_connected && (
              <Button size="small" className={st.btn} onClick={handle_openR}>
                JOIN
              </Button>
            )}
          </div>

          {is_connected && (
            <NavLink to="/profile">
              <Button size="small" className={st.btn}>
                My Profile
              </Button>
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
}

export default NavbarMobile;
