import { Check } from "lucide-react";
import React from "react";
import toast from "react-hot-toast";

const initState = {
  steps: [
    {
      title: "1",
      Component: () => {},
    },
  ],
  activeStep: 0,
  setActiveStep: () => {},
};

const Stepper = ({ steps, activeStep, setActiveStep } = initState) => {
  return (
    <div className="w-full h-[48px] my-3 flex items-center justify-between relative">
      <div className="w-full h-[3px] bg-borderGray absolute" />

      {steps.map((step, index) => {
        return (
          <div
            className={`
                text-xl h-full aspect-square rounded-full flex items-center justify-center text-white cursor-pointer relative
                ${index === activeStep ? "bg-black" : "bg-gray-500"}`}
            key={index}
            onClick={() => {
              activeStep > index - 1
                ? setActiveStep(index)
                : toast.error("Please complete the previous step");
            }}
          >
            {activeStep > index ? <Check /> : step.title}
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
